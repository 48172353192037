$(function(){
  $(window).scroll(function (){
    $('.fadein').each(function(){
      var elemPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > elemPos - windowHeight + 150){
        $(this).addClass('scrollin');
      }else{
        $(this).removeClass('scrollin'); //スクロールするたびに実行するための記述
      }
    });
  });
});