// 投稿一覧のコメント表示非表示
$(function(){
  // みんなのいきたいところのコメント部分
  $(".js-area-comment").on("click", function(){
    console.log($(this).parents().next())
    var id = $(this).data('id');
    console.log(id);
    console.log('.js-comment-'+id+'');
    $(`#js-comment-${id}`).slideToggle();
    $(this).toggleClass("comment_clicked"); //コメントボタンを修飾
  });

  // みんなのやりたいことのコメント部分
  $(".js-bucket-comment").on("click", function(){
    console.log($(this).parents().next())
    var id = $(this).data('id');
    console.log(id);
    console.log('.js-comment-'+id+'');
    $(`#js-comment-${id}`).slideToggle();
    $(this).toggleClass("comment_clicked"); //コメントボタンを修飾
  });

  // みんなのエピソードのコメント部分
  $(".js-episode-comment").on("click", function(){
    var id = $(this).data('id');
    console.log(id);
    console.log('.js-comment-'+id+'');
    $(`#js-comment-${id}`).slideToggle();
    $(this).toggleClass("comment_clicked"); //コメントボタンを修飾
  });

});

// ユーザー新規作成画面の説明部分の表示非表示
$(function(){
  $("#js-email-at-create").on("click", function(){
    console.log('メールアドレス入力フォームがクリックされたよ！')
    $('#js-email-expalin').slideDown();
    $('#js-password-expalin').slideUp();
    $('#js-confirmation-expalin').parent().next().slideUp();
  });

  $("#js-password-at-create").on("click", function(){
    console.log('パスワード入力フォームがクリックされたよ！')
    $('#js-password-expalin').slideDown();
    $('#js-email-expalin').slideUp();
    $('#js-confirmation-expalin').slideUp();
  });

  $("#js-passwordconfirmation-at-create").on("click", function(){
    console.log('パスワード確認入力フォームがクリックされたよ！')
    $('#js-confirmation-expalin').slideDown();
    $('#js-email-expalin').slideUp();
    $('#js-password-expalin').slideUp();
  });
});

// プロフィール新規作成ページの説明部分の表示非表示
$(function(){
  $("#js-nickname-at-profile").on("click", function(){
    $(this).parents().next().slideDown();
    $("#js-message-at-profile").parent().next().slideUp();
    $("#js-visited-country").parent().next().slideUp();
  });

  $("#js-message-at-profile").on("click", function(){
    $(this).parents().next().slideDown();
    $("#js-nickname-at-profile").parent().next().slideUp();
    $("#js-visited-country").parent().next().slideUp();
  });

  $("#js-visited-country").on("click", function(){
    $(this).parents().next().slideDown();
    $("#js-nickname-at-profile").parent().next().slideUp();
    $("#js-message-at-profile").parent().next().slideUp();
  });
});

// 大使館情報の詳細の表示非表示
$(function(){
  $("#js-mail-title-0").on("click", function(){
    console.log('メールのタイトルがクリックされたよ！')
    $(this).next().slideToggle();
  });
  $("#js-mail-title-1").on("click", function(){
    console.log('メールのタイトルがクリックされたよ！')
    $(this).next().slideToggle();
  });
  $("#js-mail-title-2").on("click", function(){
    console.log('メールのタイトルがクリックされたよ！')
    $(this).next().slideToggle();
  });
  $("#js-mail-title-3").on("click", function(){
    console.log('メールのタイトルがクリックされたよ！')
    $(this).next().slideToggle();
  });
  $("#js-mail-title-4").on("click", function(){
    console.log('メールのタイトルがクリックされたよ！')
    $(this).next().slideToggle();
  });
});