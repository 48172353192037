window.addEventListener("scroll", function () {
  var header = document.querySelector("header");
  header.classList.toggle("scroll-nav", window.scrollY > 680);
});

jQuery(function($){
  $(window).on('scroll', function(){
    if ($(window).scrollTop() > 680) {
      $('#js-search-at-header').fadeIn(200);
    } else {
      $('#js-search-at-header').fadeOut(200);
    }
    if (($(window).scrollTop() > 10) && (window.innerWidth <= 768)) {
      $('.FixedMenuSection').fadeIn(200);
    } else {
      $('.FixedMenuSection').fadeOut(200);
    }
  });

  $(window).on('resize',function(){
    //windowの幅をxに代入
    var x = $(window).width();
    //windowの分岐幅をyに代入
    var y = 768;
    if (x <= y) {
      $('#header-layout').addClass('justify-content-center').removeClass('justify-content-between');
    }else{
      $('#header-layout').addClass('justify-content-between').removeClass('justify-content-center');
    }
  });
});
