// ユーザー新規作成画面にてメール入力フォームをクリックすると０％から１５％に
$(function(){
  $("#js-email-at-create").on("click", function(){
    $(".progress-bar").css('width', '15%');
  });
});

// ユーザー新規作成画面にてパスワード入力フォームをクリックすると１５％から４０％に
$(function(){
  $("#js-password-at-create").on("click", function(){
    $(".progress-bar").css('width', '40%');
  });
});

// ユーザー新規作成画面にてパスワード確認入力フォームをクリックすると４０％から５０％に
$(function(){
  $("#js-passwordconfirmation-at-create").on("click", function(){
    $(".progress-bar").css('width', '50%');
  });
});

// プロフィール作成画面にてニックネームを入力すると50%から60%に
$(function(){
  $("#js-nickname-at-profile").on("click", function(){
    console.log('ニックネームの入力フォームがクリックされたよ！')
    $(".progress-bar").css('width', '60%');
  });
});
// プロフィール作成画面にてひとことを入力すると60%から80%に
$(function(){
  $("#js-message-at-profile").on("click", function(){
    console.log('ひとことの入力フォームがクリックされたよ！')
    $(".progress-bar").css('width', '80%');
  });
});
// プロフィール作成画面にていきたい場所を入力すると80%から100%に
$(function(){
  $("#js-visited-country").on("click", function(){
    console.log('いきたいところの入力フォームがクリックされたよ！')
    $(".progress-bar").css('width', '100%');
  });
});