// $(function () {
//   // オーバーレイとモーダルフェードイン
//   $('.js-open').click(function () {
//     // 背景を固定させるクラスを付与
//     $("body").addClass("no_scroll"); 
//     $('#overlay, .modal-window').fadeIn();
//   });

//   // オーバーレイとモーダルフェードアウト
//   $('.js-close, #overlay').click(function () {
//     // 背景固定させるクラス削除
//     $("body").removeClass("no_scroll");
//     $('#overlay, .modal-window').fadeOut();
//   });
// });

// ユーザー詳細画面のプロフィールを更新するモーダル
$(function () {
  // オーバーレイとモーダルフェードイン
  $('.js-edit-open').click(function () {
    // 背景を固定させるクラスを付与
    $("body").addClass("no_scroll"); 
    var id = $(this).data('id'); // 何番目のキャプション（モーダルウィンドウ）か認識
    $('#overlay, .modal-window[data-id="modal-' + id + '"]').fadeIn();
  });
  // オーバーレイとモーダルフェードアウト
  $('.js-close, #overlay').click(function () {
    // 背景固定させるクラス削除
    $("body").removeClass("no_scroll");
    $('#overlay, .modal-window').fadeOut();
  });
});

// ユーザー詳細画面のコンテンツを追加するモーダル
// $(function () {
//   // オーバーレイとモーダルフェードイン
//   $('.js-add-open').click(function () {
//     // 背景を固定させるクラスを付与
//     $("body").addClass("no_scroll"); 
//     var id = $(this).data('id'); // 何番目のキャプション（モーダルウィンドウ）か認識
//     $('#overlay, .modal-window[data-id="modal-' + id + '"]').fadeIn();
//   });
//   // オーバーレイとモーダルフェードアウト
//   $('.js-close, #overlay').click(function () {
//     // 背景固定させるクラス削除
//     $("body").removeClass("no_scroll");
//     $('#overlay, .modal-window').fadeOut();
//   });
// });

// ユーザー詳細画面のコンテンツを追加するモーダル改変
$(function () {
  // オーバーレイとモーダルフェードイン
  $(document).on('click',".js-add-open",function () {
    console.log('モーダルを起動しました(js)');
    // 背景を固定させるクラスを付与
    $("body").addClass("no_scroll"); 
    var id = $(this).data('id'); // 何番目のキャプション（モーダルウィンドウ）か認識
    $('#overlay, .modal-window[data-id="modal-' + id + '"]').fadeIn();
  });
  // オーバーレイとモーダルフェードアウト
  $(document).on('click','.js-close, #overlay',function () {
    // 背景固定させるクラス削除
    $("body").removeClass("no_scroll");
    $('#overlay, .modal-window').fadeOut();
  });
});