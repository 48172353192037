require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// require ('../packs/dropdown')
require ('../packs/header')
require ('../packs/fadein')
require ('../packs/accordion')
require ('../packs/progressbar')
require ('../packs/tab_change')
require ('../packs/modal')
require ('../packs/flash_message')

// JQVMAPの表示
// require ('../packs/world_map')
require ('../packs/maps/jqv/jquery.vmap')
require ('../packs/maps/jqv/jquery.vmap.world')
require ('../packs/maps/jqv/jquery.vmap.sampledata')

import 'bootstrap';
import '../stylesheets/application.scss';