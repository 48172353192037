

$(function(){
  $('.late-read').fadeIn(1000);

  $('.tab-content>div').hide();
  $('.tab-content>div').first().slideDown();
    $('.tab-buttons span').click(function(){
      var thisclass=$(this).attr('class');
      $('#lamp').removeClass().addClass('#lamp').addClass(thisclass);
      $('.tab-content>div').each(function(){
        if($(this).hasClass(thisclass)){
          $(this).fadeIn(800);
        }
        else{
          $(this).hide();
        }
      });
    });
    $('.tab-buttons span.content1').click(function(){
      $(this).css('background', 'white')
      $(this).css('border-top-right-radius', '15px')
      $(this).css('color', '#333')
      $('.tab-buttons span.content2').css('background', '#f4f6f6')
      $('.tab-buttons span.content2').css('color', '#999999')
      $('.tab-buttons span.content3').css('background', '#f4f6f6')
      $('.tab-buttons span.content3').css('color', '#999999')
    });
    $('.tab-buttons span.content2').click(function(){
      $(this).css('background', 'white')
      $(this).css('border-top-right-radius', '15px')
      $(this).css('border-top-left-radius', '15px')
      $(this).css('color', '#333')
      $('.tab-buttons span.content1').css('background', '#f4f6f6')
      $('.tab-buttons span.content1').css('color', '#999999')
      $('.tab-buttons span.content3').css('background', '#f4f6f6')
      $('.tab-buttons span.content3').css('color', '#999999')
    });
    $('.tab-buttons span.content3').click(function(){
      $(this).css('background', 'white')
      $(this).css('border-top-left-radius', '15px')
      $(this).css('color', '#333')
      $('.tab-buttons span.content1').css('background', '#f4f6f6')
      $('.tab-buttons span.content1').css('color', '#999999')
      $('.tab-buttons span.content2').css('background', '#f4f6f6')
      $('.tab-buttons span.content2').css('color', '#999999')
    });
});
